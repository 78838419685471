import { render, staticRenderFns } from "./Wall.vue?vue&type=template&id=447c969a&scoped=true&"
import script from "./Wall.vue?vue&type=script&lang=js&"
export * from "./Wall.vue?vue&type=script&lang=js&"
import style0 from "./Wall.vue?vue&type=style&index=0&id=447c969a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "447c969a",
  null
  
)

export default component.exports